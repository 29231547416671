<template>
    <div>
        <!--搜索框-->
        <div style="display: flex;justify-content: flex-start;padding-top: 30px">
            <div>
                分类名称：
                <el-input placeholder="请输入分类名称" clearable v-model="typeName"></el-input>
            </div>

            <div style="padding-left: 30px">
                <el-button type="primary" size="small" @click="findAll">搜索</el-button>
            </div>

            <div style="padding-left: 30%">
                <el-button type="primary" size="small" @click="showAdd">新增</el-button>
            </div>
        </div>

        <div style="padding-top: 50px;" v-loading="loading">
            <el-table
                    :data="list"
                    border
                    style="width: 70%">
                <el-table-column
                        type="index"
                        label="序号"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="typeName"
                        label="项目分类名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="添加时间"
                        align="center">
                </el-table-column>
                <el-table-column
                        align="center"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showEdit(scope.row)">编辑</el-button>
                        <el-button type="text" @click="deleteById(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
                title="编辑分类"
                :visible.sync="centerDialogVisible"
                width="30%"
                :before-close="beforeClose"
                center>
            <div>
                项目分类名称：
                <el-input placeholder="请输入分类名称" clearable v-model="typeEdit.typeName"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="beforeClose">取 消</el-button>
    <el-button type="primary" @click="submitEdit">确 定</el-button>
  </span>
        </el-dialog>

        <el-dialog
                title="新增分类"
                :visible.sync="centerDialogVisible2"
                width="30%"
                center>
            <div>
                项目分类名称：
                <el-input placeholder="请输入分类名称" clearable v-model="addType"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible2 = false">取 消</el-button>
    <el-button type="primary" @click="submitAdd">确 定</el-button>
  </span>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "ProjectTypeList",
        data() {
            return {
                list: [],
                loading: false,
                typeName: '',
                typeEdit: {},
                centerDialogVisible: false,
                centerDialogVisible2:false,
                addType:''
            }
        },
        methods: {
            deleteById(id){
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/project/deleteTypeById?id='+id).then(resp=>{
                        if (resp.data.code==200){
                            this.$message.success(resp.data.message)
                            this.findAll()
                        }
                    })
                })
            },
            submitAdd(){
              if (!this.addType||this.addType.trim()==''){
                  this.$message.warning('请输入分类名称')
                  return false;
              }
              this.axios.get('/project/projectTypeAdd?typeName='+this.addType).then(resp=>{
                  if (resp.data.code==200){
                      this.centerDialogVisible2=false
                      this.$message.success(resp.data.message)
                      this.findAll()
                  }
              })
            },
            showAdd(){
                this.addType=''
                this.centerDialogVisible2=true;
            },
            submitEdit(){
              this.axios.post('/project/projectTypeEdit',this.typeEdit).then(resp=>{
                  if (resp.data.code==200){
                      this.centerDialogVisible=false
                      this.$message.success(resp.data.message)
                      this.findAll()
                  }
              })
            },
            beforeClose(){
                this.centerDialogVisible=false
                this.typeEdit={}
                this.findAll();
            },
            showEdit(type) {
                this.typeEdit = type
                this.centerDialogVisible = true
            },
            findAll() {
                this.loading = true;
                this.axios.get('/project/findTypeAll?typeName=' + this.typeName).then(resp => {
                    if (resp.data.code == 200) {
                        this.list = resp.data.data
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        created() {
            this.findAll();
        }
    }
</script>

<style scoped>
    .el-input {
        width: 300px;
    }
</style>
